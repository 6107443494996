<template>
  <div class="dialog-container convenienceDialog">
    <el-dialog
      :title="`${isEdit ? '修改' : '新增'}`"
      :visible.sync="show"
      @opened="openDialog"
      :before-close="closeDialog"
      top="10px"
      center
      :close-on-click-modal="false"
      width="624px"
    >
      <el-form ref="form" :model="form" label-width="100px" :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item label="场馆名称" prop="venueName">
              <el-input v-model="form.venueName" maxlength="30"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="主营方向" prop="mainDirection">
              <el-select v-model="form.mainDirection" multiple style="width:100%">
                <el-option v-for="item in mainDirectionBox" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="可容纳人数" prop="accommodateQty">
              <el-input clearable type="number" @change="e => form.accommodateQty = e > 1000000 ? 1000000 : e" v-model="form.accommodateQty"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="场馆地址" prop="address">
              <el-input v-model="form.address" maxlength="60"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="场馆简介" prop="briefIntroduction">
              <el-input clearable type="textarea" rows="4" v-model="form.briefIntroduction" maxlength="100"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="商家状态" prop="reservationSupport">
              <el-switch v-model="form.reservationSupport" active-value="Y" inactive-value="N"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="地图标注" prop="point" :required="isRequired">
              <div class="bmap-wrap">
                <new-map  v-if="showMap" ref="bMap" :address.sync="form.mapAnnotation" :point.sync="form.point" :isEdit="isEdit" @getPoint="firstGet" @onMapAdress="getRAdress" @getRegion="getRegion" height="300px"></new-map>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{$t('sure')}}</el-button>
        <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import mixin from "@/mixins/dialogMixin";
import AddDialogClass from "./addDialogClass";
import apis from "@/apis";
import newMap from "../../venue/dialog/bMap.vue";

export default {
  mixins: [mixin],
  components: {
    newMap,
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      baseImage: "",
      form: new AddDialogClass("form"), // 实例化一个表单的变量
      rules: new AddDialogClass("rule", this.Language), // 实例化一个表单的规则
      apis,
      showMap: true,
      isRequired: true,
      mainDirectionBox: [],
    };
  },
  computed: {
    ...mapState(["DROPDOWNBOX", "TOKEN", "venueList"]),
  },
  methods: {
    getRAdress(address, point) {
      console.log('getRAdress-add', address)
      this.form.mapAnnotation = address.address;
      this.form.point.lat = point.lat;
      this.form.latitude = point.lat;
      this.form.point.lng = point.lng;
      this.form.longitude = point.lng;
    },
    getRegion(region) {
      this.form.region = region;
    },
    /**
     * @function 关闭弹窗
     * @description 先初始化，然后传值到组件外部进行关闭
     **/
    async closeDialog() {
      await this.init()
      this.$refs.bMap.clearData();
      this.$emit('closeDialog', false)
    },
    ...mapMutations(['setVenueId', "setVenueList"]),
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields();
      this.form = new AddDialogClass("form");
      console.log("我初始化了");
    },
    firstGet(point, address) {
      console.log('getfist')
      this.form.point.lat = point.lat;
      this.form.point.lng = point.lng;
      this.form.mapAnnotation = address;
    },
    // 地图选点成功返回
    onGetPoint(point, address) {
      this.form.point.lat = point.lat;
      this.form.point.lng = point.lng;
      this.form.mapAnnotation = address;
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          const formData = { ...this.form };
          formData.latitude = formData.point.lat;
          formData.longitude = formData.point.lng;
          formData.mainDirection = formData.mainDirection.join(',')
          this.$http.post(this.isEdit ? apis.updateVenueInfo : apis.insertVenueInfo, formData).then((res) => {
            if (res.data.code === 0) {
              this.closeDialog()
            }
          })
        }
      })
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data) {
      // 在这里获取回显的数据
      this.$http.get(`${apis.venueInfoDetail}/${data.id}`).then((res) => {
        if (res.data.code === 0) {
          this.form = {
            ...res.data.data,
            ...{
              mainDirection: res.data.data.mainDirection ? res.data.data.mainDirection.split(',') : [],
              longitude: res.data.data.longitude,
              latitude: res.data.data.latitude,
              mapAnnotation: res.data.data.mapAnnotation,
              point: {
                lng: Number(res.data.data.longitude),
                lat: Number(res.data.data.latitude),
              },
            },
          };
          this.showMap = false
          this.$nextTick(() => {
            this.showMap = true
          })
        }
      });
    },
    openDialog() {
      if (!this.isEdit) {
        this.showMap = false
        this.$nextTick(() => {
          this.showMap = true
        })
      }
      this.$http.get(apis.getMainDirectionList).then((res) => {
        if (res.data.code === 0) {
          this.mainDirectionBox = res.data.rows
        }
      })
    },
    updatePoint(point) {
      this.form.point = point
      this.showMap = false
      this.$nextTick(() => {
        this.showMap = true
      })
      console.log(point)
    },
  },
};
</script>
<style lang="less" scoped>
.image-upload {
  width: 50px;
  height: 50px;
}
.upload-images-tip {
  color:rgba(168,168,168,1);
  font-size:12px;
  margin-top: -15px;
}
.bmap-wrap {
  width: 100%;
}
.image-wrap{
  width: 50px;
  height: 50px;
  margin-right: 10px;
  position: relative;
  float: left;
  .images-close {
    position: absolute;
    right: -7px;
    top: -7px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}
</style>
