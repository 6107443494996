/*
 * @Author: your name
 * @Date: 2020-03-23 11:19:40
 * @LastEditTime: 2020-10-14 17:37:03
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\venue\dialog\addDialogClass.js
 */
/* eslint-disable */
class searchFrom {
    constructor(type) {
      if (type === 'form') {
        this.venueName = ''
        this.mainDirection = []
        this.accommodateQty = ''
        this.longitude = ''
        this.longitude = ''
        this.mapAnnotation = ''
        this.address = ''
        this.reservationSupport = 'N'
        this.briefIntroduction = ''
        this.region = ''
        // 自己用
        this.point = {}
      } else if (type === 'rule') {
        this.venueName = [{ required: true, message: '请输入场馆名称', trigger: 'blur' }]
        this.mainDirection = [{ required: true, message: '请选择主营方向', trigger: ['change', 'blur'] }]
        this.point = [{ required: true, message: '请选择地址', trigger: ['change', 'blur'] }]
        this.accommodateQty = [{ required: true, message: '请输入可容纳人数', trigger: ['change', 'blur'] }]
        this.address = [{ required: true, message: '请输入场馆地址', trigger: ['change', 'blur'] }]
        this.reservationSupport = [{ required: true, message: '请选择商家状态', trigger: ['change', 'blur'] }]
        this.mapAnnotation = [{ required: true, message: '请输入地址', trigger: ['change', 'blur'] }]
        this.briefIntroduction = [{ required: true, message: '请输入场馆简介', trigger: ['change', 'blur'] }]
      }
    }
  }
  export default searchFrom
  