/*
 * @Author: your name
 * @Date: 2020-09-10 09:53:04
 * @LastEditTime: 2020-09-10 10:16:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-arc-view\src\views\venueMana\baseVenue\indexClass.js
 */
import pageClass from '@/unit/pageClass'

class searchFrom extends pageClass {
  constructor(type) {
    super()
    if (type === 'form') {
      this.venueName = ''
      this.mainDirection = ''
    }
  }
}
export default searchFrom
